import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from "react-router-dom"
import './App.css'

import Home from '../views/home/Home'
import About from '../views/about/About'
import Contact from '../views/Contact/Contact'

import PopUp from '../components/popUp/popUp';

function App() {
    const location = useLocation();

    useEffect(() => {
        // Scroll top when location changes
        window.scrollTo(0, 0);
    }, [location]);

    // This will run one time after the component mounts
    useEffect(() => {
        const onPageLoad = () => {
            console.log('Page Loaded')
        };
        console.log('asdasd')

        // Check if the page has already loaded
        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('DOMContentLoaded', onPageLoad);
            // Remove the event listener when component unmounts
            return () => window.removeEventListener('DOMContentLoaded', onPageLoad);
        }
    }, []);


    return (
        <div className="App">
            <PopUp/>

            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/about" element={<About/>} />
                <Route path="/contact" element={<Contact/>} />
            </Routes>
        </div>
    );
}

export default App;
