import axios from 'axios'
import { AxiosResponse, AxiosError } from 'axios'
import { defaultSuccess, contactRequest } from './types';

//const BACKEND_URL = 'https://api.ireserve.smartssi.net'
const BACKEND_URL = 'https://api.ireserve.io'

export function contactUS(payload: contactRequest) {
  //const config = useContext(ConfigContext)

  const axiosOptions = {
    method: 'POST',
    url: `${BACKEND_URL}/contactus`,
    data: JSON.stringify({ ...payload })
  };

  return new Promise<AxiosResponse<defaultSuccess>>((resolve, reject) => {
    axios(axiosOptions)
    .then(function (response) {
      // handle success
      resolve(response);
    })
    .catch(function (error) {
      // handle error
      console.log(error)
      reject('No possible to authenticate')
    })
  })
}