import React from 'react';
import styles from './Home.module.css'
import NavBar from '../../components/NavBar/NavBar';
//import Hero from '../../components/Hero/Hero';
import Hero from '../../components/Hero/Hero';
import WhatIs from '../../components/WhatIs/WhatIs';
import WhatDoes from '../../components/WhatDoes/WhatDoes';
import Collaborations from '../../components/Collaborations2/Collaborations';
import Comments from '../../components/Comments/Comments';
import Footer from '../../components/Footer/Footer';
import ReadyFooter from '../../components/ReadyFooter/ReadyFooter';


export default function Home () {
    /* 
    <NavBar color="white"/>
            <Hero/>
            <WhatIs/>
            <WhatDoes/>
            <Collaborations/>
            <Comments/>
            <Footer/>
    */

    return(
        <div className={styles["home-main"]}>
            <NavBar color="white"/>
            <Hero/>
            <WhatIs/>
            <WhatDoes/>
            <Collaborations/>
            <Comments/>
            <Footer/>
            <ReadyFooter/>
        </div>
    )
}